import { mapActions, mapState } from 'vuex';
import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';
export default {
  name: "ListReception",
  data() {
    return {
      loading: false,
      transfersFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        status: null,
        //por confirmar
        sender_user_id: null,
        origin_service: null,
        destination_service: null,
        destination_services:[]
      },
      headers: [
        {
          text: 'Destino',
          align: 'start',
          sortable: false,
          value: 'destination_service.name',
        },
        {
          text: 'Origen',
          align: 'start',
          sortable: false,
          value: 'origin_service.name',
        },
        {
          text: 'Fecha de traslado',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'Fecha de recepción',
          align: 'start',
          sortable: false,
          value: 'reception_date',
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions',
        },
      ],
      status: [
        { name: 'MOVILIZANDO' },
        { name: 'CANCELADO' },
        { name: 'RECEPCIONADO' },
        { name: 'RECHAZADO' },
      ],
      items: [],

    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Inventario",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'AssetsPermissions',
          },
        },
        {
          text: "Lista de Recepciones",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: '/',
          // },
        },
      ];
    },
    ...mapState('auth', ['user']),
    ...mapState('security', ['costCenters', 'managements','costCenters']),
    ...mapState('transfer', ['transfers', 'transfersPagination', 'loadingTransfer'])
  },
  created() {
    this.getCostCenters();
    this.getTransfers();
    this.loadDataDummy()
  },
  methods: {
    ...mapActions('transfer', ['listTransfer', 'cleanTransfers']),
    ...mapActions('security', ['getCostCenters', 'getManagements']),

    loadDataDummy() {
      this.items = [
        {
          origin: 'Yanacocha',
          destination: 'TI',
          created_at: '15/10/2023 10:00:00',
          received_at: '30/10/2023 10:00:00',
          status: 'MOVILIZANDO',
          id: 1
        },
        {
          origin: 'Chinalco',
          destination: 'TI',
          created_at: '20/10/2023 10:00:00',
          received_at: '30/10/2023 10:00:00',
          status: 'CANCELADO',
          id: 2
        },
        {
          origin: 'Marsa',
          destination: 'TI',
          created_at: '25/10/2023 10:00:00',
          received_at: '30/10/2023 10:00:00',
          status: 'RECEPCIONADO',
          id: 3
        },
        {
          origin: 'Cerro colorado',
          destination: 'TI',
          created_at: '25/10/2023 10:00:00',
          received_at: '30/10/2023 10:00:00',
          status: 'RECEPCIONADO (*)',
          id: 4
        },
        {
          origin: 'Nexa Cerro Lindo',
          destination: 'TI',
          created_at: '25/10/2023 10:00:00',
          received_at: '30/10/2023 10:00:00',
          status: 'RECHAZADO',
          id: 5
        }
      ]
    },
    getStatusColor(status) {
      switch (status) {
        case 'MOVILIZANDO':
          return 'blue accent-4';
        case 'CANCELADO':
          return 'red';
        case 'RECEPCIONADO':
          // case 'RECEPCIONADO(*)':
          return 'green';
        case 'RECHAZADO':
          return 'blue-grey';
        default:
          return '';
      }
    },

    getColorStatus(statusx) {
      switch (statusx) {
        case 'MOVILIZANDO':
          return {
            status: 'MOVILIZANDO',
            background: 'blue lighten-5',
            color: 'blue',
            name: 'MOVILIZANDO',
          };
        case 'CANCELADO':
          return {
            status: 'CANCELADO',
            background: 'red lighten-5',
            color: 'red',
            name: 'CANCELADO',
          };
        case 'RECEPCIONADO':
        case 'RECEPCIONADO (*)':
          return {
            status: 'RECEPCIONADO',
            background: 'green lighten-5',
            color: 'green',
            name: 'RECEPCIONADO',
          };
        case 'RECHAZADO':
          return {
            status: 'RECHAZADO',
            background: 'grey lighten-2',
            color: 'grey',
            name: 'RECHAZADO',
          };
        default:
          return '';
      }
    },

    async getTransfers() {
      this.loading = true;
      const services = await this.getServices(this.user.id)
      this.transfersFilters.destination_services = services;
      const { error } = await this.listTransfer(this.transfersFilters);
      if (error) showError(error);
      this.loading = false;
    },

    async getServices(userId){
      const { error } = await this.getManagements({ user_id: userId });
      if (error) showError(error);
      const services = this.costCenters.map(costCenter => costCenter.id);
      return services;
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },

    async searchTransferByStatus() {
      this.transfersFilters.page = 1;
      await this.getTransfers()
    },

    async searchTransferByService() {
      this.transfersFilters.page = 1;
      await this.getTransfers()
    }
  },
  beforeDestroy() {
    this.cleanTransfers();
  },
};