<template>
	<SpareTypePermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import SpareTypePermissions from '@/components/permissions/configuration/spareSubtype/index.vue';

export default {
	name: 'SpareTypePermissionsPage',
	components: { SpareTypePermissions },
};
</script>
