import { showError } from '@/helpers/globalHelpers';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
export default {
  name: "ListTransfer",
  data() {
    return {
      loading: false,
      transfersFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        status: null,
        //por confirmar
        sender_user_id: null,
        origin_service: null,
        destination_service: null
      },
      headers: [
        {
          text: 'Origen',
          align: 'start',
          sortable: false,
          value: 'origin_service.name',
        },
        {
          text: 'Destino',
          align: 'start',
          sortable: false,
          value: 'destination_service.name',
        },
        {
          text: 'Fecha de Traslado',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'Fecha de Recepción',
          align: 'start',
          sortable: false,
          value: 'reception_date',
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions',
        },
      ],
      status: [
        { name: 'MOVILIZANDO' },
        { name: 'CANCELADO' },
        { name: 'RECEPCIONADO' },
        { name: 'RECHAZADO' },
      ],
      //

      items: [],

    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Inventario",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'AssetsPermissions',
          },
        },
        {
          text: "Lista de Traslados",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: '/',
          // },
        },
      ];
    },
    ...mapState('auth', ['user']),
    ...mapState('security', ['costCenters']),
    ...mapState('transfer', ['transfers', 'transfersPagination', 'loadingTransfer'])
  },

  created() {
    this.getCostCenters();
    this.getTransfers();
    this.loadDataDummy();
  },

  methods: {
    ...mapActions('transfer', ['listTransfer', 'cleanTransfers']),
    ...mapActions('security', ['getCostCenters']),

    async getTransfers() {
      this.loading = true;
      this.transfersFilters.sender_user_id = this.user.id;
      const { error } = await this.listTransfer(this.transfersFilters);
      if (error) showError(error);
      this.loading = false;
    },

    loadDataDummy() {
      this.items = [
        {
          origin: 'TI',
          destination: 'Yanacocha',
          created_at: '15/10/2023 14:48:10',
          status: 'MOVILIZANDO',
          received_at: '20/10/2023 10:25:10',
          id: 1
        },
        {
          origin: 'TI',
          destination: 'Chinalco',
          created_at: '20/10/2023 14:48:10',
          received_at: '20/10/2023 10:25:10',
          status: 'CANCELADO',
          id: 2
        },
        {
          origin: 'TI',
          destination: 'Marsa',
          created_at: '25/10/2023 14:48:10',
          received_at: '20/10/2023 10:25:10',
          status: 'RECEPCIONADO',
          id: 3
        },
        {
          origin: 'TI',
          destination: 'Cerro colorado',
          created_at: '25/10/2023 14:48:10',
          received_at: '20/10/2023 10:25:10',
          status: 'RECEPCIONADO (*)',
          id: 4
        },
        {
          origin: 'TI',
          destination: 'Nexa Cerro Lindo',
          received_at: '20/10/2023 10:25:10',
          created_at: '25/10/2023 14:48:10',
          status: 'RECHAZADO',
          id: 5
        }
      ]
    },

    getStatusColor(status) {
      switch (status) {
        case 'MOVILIZANDO':
          return 'blue accent-4';
        case 'CANCELADO':
          return 'red';
        case 'RECEPCIONADO':
          // case 'RECEPCIONADO(*)':
          return 'green';
        case 'RECHAZADO':
          return 'blue-grey';
        default:
          return '';
      }
    },

    getColorStatus(statusx) {
      switch (statusx) {
        case 'MOVILIZANDO':
          return {
            status: 'MOVILIZANDO',
            background: 'blue lighten-5',
            color: 'blue',
            name: 'MOVILIZANDO',
          };
        case 'CANCELADO':
          return {
            status: 'CANCELADO',
            background: 'red lighten-5',
            color: 'red',
            name: 'CANCELADO',
          };
        case 'RECEPCIONADO':
        case 'RECEPCIONADO (*)':
          return {
            status: 'RECEPCIONADO',
            background: 'green lighten-5',
            color: 'green',
            name: 'RECEPCIONADO',
          };
        case 'RECHAZADO':
          return {
            status: 'RECHAZADO',
            background: 'grey lighten-2',
            color: 'grey',
            name: 'RECHAZADO',
          };
        default:
          return '';
      }
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },

    async searchTransferByStatus() {
      this.transfersFilters.page = 1;
      await this.getTransfers()
    },

    async searchTransferByService() {
      this.transfersFilters.page = 1;
      await this.getTransfers()
    }
  },
  beforeDestroy() {
		this.cleanTransfers();
	},
};