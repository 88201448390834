import readXlsxFile from "read-excel-file";
import { mapActions } from "vuex";
import { $EventBus } from "@/main";
import moment from 'moment';
import { validateFileSize, validExtension } from '@/helpers/validationList';
import {
  isNotEmpty,
  hasExtension,
  hasTitles,
} from "@/helpers/validationList.js";

export default {
  name: "ImportMassiveAreaDialog",
  data() {
    return {
      mode: "FORM",
      selectedFile: null,
      importMassiveForm: {
        selectedFile: null,
      },
      fileRule: [
				(v) => !!v || 'Campo requerido',
				(v) =>
					validExtension(v, [
						'.xslx',
						'.xlsx',
					]) || 'La extensión del archivo no es válida',
				(v) =>
					validateFileSize(v, 10) ||
					'"El archivo es demasiado grande. Máximo 10 MB',
			],
      dialog: false,
      listError: [],
      valid: false,
      loading: false,
      loadingSave: false,
      filelist: [],
      savedAreas: [],
      headers: [
        { text: "Nombre de área", sortable: false, value: "name" },
        { text: "Documento de Responsable", sortable: false, value: "responsible_document" },
        { text: "Estado", sortable: false, value: "status", hidden: true }
      ],
      headers2: [
        { text: "Nombre de área", sortable: false, value: "area.name" },
        { text: "Documento de Responsable", sortable: false, value: "area.responsible_document" },
        { text: "Estado", sortable: false, value: "messages" }
      ],
    };
  },
  computed: {
    dialogWidth() {
      return this.mode !== "FORM" ? 1200 : 500;
    },
    filteredHeaders() {
      return this.headers.filter(header => header.value !== 'status');
    },
  },
  methods: {
    ...mapActions("asset", ["createAssetMultiple", "downloadAssetFormat"]),

    ...mapActions("area", ["createAreaMultiple", "downloadAreaFormat"]),

    dialogInput(value) {
      this.$refs.button.$el.blur();
      this.dialog = value;
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.$refs?.form?.resetValidation();
      // if (!value) {
      //   this.$nextTick(() => {
      //     this.classificationForm = {
      //       name: "",
      //       area: null,
      //       responsible_id: null,
      //     };
      //     this.listError = [];
      //     this.$refs?.form?.resetValidation();
      //   });
      // }
    },

    getMessage(success) {
      return success ? { status: "GUARDADO", color: "green" } : { status: "NO GUARDADO", color: "red" }
    },

    async changeFile() {
      // this.filelist = [];

      // try {
      //   const file = this.importMassiveForm.selectedFile;

      //   if (hasExtension(file, [".xlsx"])) {

      //     const rows = await readXlsxFile(file);

      //     const titles = [
      //       "Nombre de Área",
      //       "Documento de Responsable"
      //     ];

      //     if (isNotEmpty(rows) && hasTitles(rows, titles)) {

      //       let hasError = false;

      //       rows.forEach((row, index) => {

      //         if (index > 0 ) {
      //           if (row[0] ) {
      //             this.filelist.push({                   
      //               name: row[0],
      //               responsible_document:row[1].toString()

      //             });
      //           }
      //         }
      //       });

      //       if (!hasError) {
      //         this.mode = "UPLOAD";
      //       } else {
      //         this.mode = "FORM";
      //       }
      //     } else {
      //       this.mode = "FORM";
      //     }
      //   }
      // } catch (e) {
      //   $EventBus.$emit(
      //     "showSnack",
      //     "error",
      //     e || "Ocurrio un error al leer el archivo."
      //   );
      //   this.mode = "FORM";
      // }
      this.filelist = [];

      try {
        const file = this.importMassiveForm.selectedFile;

        if (hasExtension(file, [".xlsx"])) {

          const rows = await readXlsxFile(file);

          const titles = [
            "Nombre de Área",
            "Documento de Responsable"
          ];

          if (isNotEmpty(rows) && hasTitles(rows, titles)) {

            let hasError = false;
            let errorMessage = "";

            rows.forEach((row, index) => {

              if (index > 0) {
                if (!row[0] || !row[1]) {
                  hasError = true;
                  errorMessage = !row[0]
                    ? "El nombre de área es requerido en todas las filas."
                    : "El documento de responsable es requerido en todas las filas.";
                  return; // Sale del bucle forEach
                }

                this.filelist.push({
                  name: row[0],
                  responsible_document: row[1].toString()
                });
              }
            });

            if (!hasError) {
              this.mode = "UPLOAD";
            } else {
              $EventBus.$emit(
                "showSnack",
                "error",
                errorMessage
              );
              this.mode = "FORM";
            }
          } else {
            this.mode = "FORM";
          }
        }
      } catch (e) {
        $EventBus.$emit(
          "showSnack",
          "error",
          e || "Ocurrió un error al leer el archivo."
        );
        this.mode = "FORM";
      }
    },

    async save() {
      this.loadingSave = true
      const { ok, error, response } = await this.createAreaMultiple(
        //company_id: parseInt(localStorage.getItem("company_id")),
        this.filelist,
      );
      if (ok) {
        // const assets = response;
        // this.savedAreas = Array.isArray(assets) ? assets : [];

        this.savedAreas = this.filelist.map((area, index) => {
          return {
            ...response[index],
            // area: response[index].area || area,
            area: area,
          };
        });
        this.mode = "SAVED";
        await this.$emit('getArea');
        // $EventBus.$emit(
        // 	'showSnack',
        // 	'success',
        // 	//response?.messages[0] || 'Se ha registrado satisfactoriamente.'
        //   response[0].messages || 'Se ha registrado satisfactoriamente.'
        // );
        this.loadingSave = false;
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(", ") : errorMsm;
        $EventBus.$emit("showSnack", "error", errorMsm);
        this.loadingSave = false;
      }
    },

    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },

    async downloadTemplate() {
      try {
        this.loading = true;
        const { ok, error } = await this.downloadAreaFormat();
        if (!ok) {
          console.error(error); // o muestra un mensaje de error usando una alerta/snackbar, por ejemplo.
        }
        this.loading = false;
      } catch (ex) {
        console.error("Hubo un error al descargar el modelo:", ex);
      }
    },

    close() {
      this.dialog = false;
      this.mode = "FORM";
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.savedAreas = [];
      this.$refs?.form?.resetValidation();
    },
  },
};
