<template>
	<ConfigurationPermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import ConfigurationPermissions from '@/components/permissions/configuration/home/ConfigurationHome.vue';

export default {
	name: 'ConfigurationPermissionsPage',
	components: { ConfigurationPermissions },
};
</script>
