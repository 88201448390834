import { mapActions } from 'vuex';

export default {
    name: 'RegisterRatingType',
    props: {
        value: Boolean,
        // item: Object,
        // //
        // returnAsset: Boolean,
    },
    data() {
        return {
            listError: [],
            valid: false,
            loadingSave: false,
            registerRatingTypeForm: {
                description: null
            },

            descriptionRules: [
                (v) => !!v || 'La descripción es requerida',
                // (v) =>
                // 	v.length <= 400 || 'El comentario debe tener menos de 400 carácteres',
            ],

        };
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    created() {
    },
    methods: {
        ...mapActions('ratingType', ['createRatingType']),

        async save() {
            if (!this.$refs?.form?.validate()) { return }

            this.listError = [];

            const data = {
                ...this.registerRatingTypeForm,
                company_id: parseInt(localStorage.getItem('company_id')),
            };

            this.loadingSave = true;

            const { ok, error } = await this.createRatingType(data);
            if (ok) {
                this.dialog=false;
                await this.$emit('getRatingsType');
                this.registerRatingTypeForm = { description: '', company_id: '' }
                this.$refs.form.resetValidation();
                this.loadingSave = false
            } else {
                this.listError = Array.isArray(error?.data?.message)
                    ? error?.data?.message
                    : [error?.data?.message] || [];
                this.loadingSave = false
            }
            this.loadingSave = false;
        },

        close() {
            this.dialog = false;
            (this.registerRatingTypeForm = { description: '', company_id: '' }),
                (this.listError = []);
            this.$refs?.form?.resetValidation();
        },

        dialogInput(value) {
            this.$nextTick(() => {
                this.$refs.dialogForm.$el.blur();
                this.$refs.form.resetValidation();
                this.dialog = value;
                if (!value) {
                    this.registerRatingTypeForm = { description: '', company_id: '' }
                    this.listError = [];
                }
            })
        },
    },
    components: {
    },
};
