import { showError } from '@/helpers/globalHelpers';
import { mapActions, mapState } from 'vuex';
import RatingTypeDialog from '../../elements/CreateRatingTypeDialog';
import moment from 'moment';
export default {
  name: 'RatingTypeView',
  data() {
    return {
      ratingTypeDialog: false,
      loading:false,
      ratingTypeFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        // asset_type: null,
        // status: null,
        //
        //assigned_user: null,
      },
      headers: [
        { text: 'Descripción', sortable: false, value: 'description' },
        { text: 'Fecha de Creación', sortable: false , value:'created_at'},
        // { text: 'Acciones', value: 'actions', sortable: false },
      ]
    }
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Permisos de Aplicación',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'PermissionsHome',
          },
        },
        {
          text: 'Configuración',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'ConfigPermissions',
          },
        },
        {
          text: 'Tipos de Calificaciones',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: 'PermissionHomePage',
          // },
        },
      ];
    },
    ...mapState('ratingType',['ratingTypes','ratingTypesPagination']),
  },

  created(){
    this.getRatingsType();
  },

  methods: {
    ...mapActions('ratingType',['listRatingType']),

    registerRatingType() {
      this.ratingTypeDialog = true;
      this.$nextTick(() => {
				document.activeElement.blur();
			});
    },

    async getRatingsType(){
      this.loading = true;
      const { error} = await this.listRatingType(this.ratingTypeFilters);
      if(error) showError(error);
      this.loading = false;
    },

    formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
  },

  components:{
    RatingTypeDialog
  }
}
