<template>
	<QualificationCriteriaPermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import QualificationCriteriaPermissions from '@/components/permissions/configuration/qualificationCriteria/index.vue';

export default {
	name: 'QualificationCriteriaPermissionsPage',
	components: { QualificationCriteriaPermissions },
};
</script>
