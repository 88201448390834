<template>
	<TransferHome />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
//import AssetsPermissions from '@/components/inventory/assets/index.vue';
// import AssetsPermissions from '@/components/inventory/Tabs/AssetsTab/assetsTab.vue'
// import TransferHome from '@/components/inventory/transfers/transfer.vue';
import TransferHome from '@/components/inventory/elements/Transfer/ListTransfer/listTransfer.vue';

export default {
	name: 'TransferHomePage',
	components: { TransferHome },
};
</script>
