<template>
	<AssetTypePermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import AssetTypePermissions from '@/components/permissions/configuration/assetSubtype/index.vue';

export default {
	name: 'AssetTypePermissionsPage',
	components: { AssetTypePermissions },
};
</script>
