import { showError } from '@/helpers/globalHelpers';
import { mapActions, mapState } from 'vuex';
import AssetSubtypeDialog from '../../elements/CreateAssetSubtypeDialog';
import UpdateAssetSubtypeDialog from '../../elements/UpdateAssetSubtypeDialog';
import DeleteTypeDialog from '../../elements/DeleteAssetSubtypeDialog';
import moment from 'moment';
export default {
  name: 'AssetSubtypeView',
  data() {
    return {
      assetTypeDialog: false,
      loading:false,
      assetsSubtypeFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        // asset_type: null,
        // status: null,
        //
        //assigned_user: null,
      },
      headers: [
        { text: 'Nombre', sortable: false, value: 'name' },
        { text: 'Fecha de Creación', sortable: false , value:'created_at'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedItem: {},
      editDialog: false,
			deleteDialog: false,
    }
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Permisos de Aplicación',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'PermissionsHome',
          },
        },
        {
          text: 'Configuración',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'ConfigPermissions',
          },
        },
        {
          text: 'Tipos de Activo',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: 'PermissionHomePage',
          // },
        },
      ];
    },
    ...mapState('assetType',['assetTypes','assetTypesPagination']),
  },

  created(){
    this.getAssetsSubtype();
  },

  methods: {
    ...mapActions('assetType',['listAssetType']),

    registerAssetSubtype() {
    
      this.assetTypeDialog = true;
      this.$nextTick(() => {
				document.activeElement.blur();
			});
    },

    async getAssetsSubtype(){
      this.loading = true;
      const { error} = await this.listAssetType(this.assetsSubtypeFilters);
      if(error) showError(error);
      this.loading = false;
    },

    editItem(item) {
			this.editedItem = Object.assign({}, item);
			this.editDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

    deleteItem(item) {
			this.editedItem = Object.assign({}, item);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

    formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
  },

  components:{
    AssetSubtypeDialog,
    UpdateAssetSubtypeDialog,
    DeleteTypeDialog
  }
}
