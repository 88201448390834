<template>
	<AreasPermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import AreasPermissions from '@/components/permissions/areas/index.vue';

export default {
	name: 'AreasPermissionsPage',
	components: { AreasPermissions },
};
</script>
