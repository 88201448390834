import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
export default {
	name: 'CreateAreaDialog',
	data() {
		return {
			dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			areaForm: {
				name: '',
				company_id: null,
			},
			nameRules: [
				(v) => !!v || 'Nombre de Área es requerido',
				(v) => v.length <= 50 || 'El nombre debe tener menos de 50 carácteres',
			],
			responsablesRules: [
				(v) => !!v || "Responsable es requerido",
			],
		};
	},
	computed: {
		...mapState({
			companies: (state) => state.auth.user?.companies,
		}),
		...mapState('security', ['usersActive']),
	},
	created() {
		// this.getResponsibles();
	},
	methods: {
		...mapActions('area', ['createArea', 'listArea']),
		//...mapActions('security', ['getUsersActive']),
		// ...mapActions('user',['getResponsibles']),
		async saveArea() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.listError = [];
			const data = {
				...this.areaForm,
				company_id: parseInt(localStorage.getItem('company_id')),
			};
			this.loadingSave = true;
			const { ok, error } = await this.createArea(data);
			if (ok) {
				this.dialog = false;
				await this.$emit('getArea');
				await this.$emit('closeCreateDialog');
				this.areaForm = { name: '', company_id: '' };
				this.$refs.form.resetValidation();
				this.loadingSave = false;
			} else {
				if (error === 'Esta área ya existe para la empresa seleccionada') {
					this.listError.push(
						'Ya existe un área con ese nombre para la misma compañía.'
					);
					this.loadingSave = false;
				} else {
					this.listError = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [error?.data?.message] || [];
						this.loadingSave = false;
				}
			}

			this.loadingSave = false;
		},

		dialogInput(value) {
			this.$nextTick(() => {
				this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.areaForm = { name: '', company_id: null };
					this.listError = [];
				}
			})
		},

		close() {
			this.dialog = false;
			this.areaForm = { name: '', company_id: null };
			this.listError = [];
			this.$refs.form.resetValidation();
		},
		customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
	},
	components: {
		ValidationAlert,
	},
};
