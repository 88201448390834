import { mapActions } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";

export default {
    name: "UpdateSpareSubtypeDialog",
    data() {
        return {
            listError: [],
            valid: false,
            loadingSave: false,
            spareSubtypeForm: {
                name: "",
            },
            nameRules: [
                (v) => !!v || "Nombre de tipo de repuesto es requerido",
                (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
            ],
        };
    },
    props: {
        value: Boolean,
        item: Object,
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                this.spareSubtypeForm = {
                    name: this.item.name,
                }
            }
        },
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        ...mapActions("spareType", ["updateSpareType"]),
        
        close() {
            this.dialog = false;
            this.spareSubtypeForm = { name: "" };
            this.listError = [];
            this.$refs.form.resetValidation();
        },
        async save() {

            if (!this.$refs?.form?.validate()) {
                return;
            }

            this.listError = [];
            const data = {
                ...this.spareSubtypeForm,
                //company_id: parseInt(localStorage.getItem("company_id")),
            };
            this.loadingSave = true;
            const { ok, error } = await this.updateSpareType({ id: this.item.id, data: data });
            if (ok) {
                this.dialog = false;
                this.$emit("getSparesSubtype");
                this.spareSubtypeForm = { name: "" };
                this.$refs?.form?.resetValidation();
            } else {
                this.listError = Array.isArray(error?.data?.message) ? error?.data?.message : [error?.data?.message] || [];
            }
            this.loadingSave = false;
        },
        dialogInput(value) {
            this.dialog = value;
            if (!value) {
                this.$nextTick(() => {
                    this.spareSubtypeForm = { name: "" };
                    this.listError = [];
                    this.$refs.form.resetValidation();
                });
            }
        },
    },
    components: {
        ValidationAlert,
    }
};