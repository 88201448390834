<template>
	<ReceptionHome />
</template>
<script>
import ReceptionHome from '@/components/inventory/elements/Transfer/ListReceptions/listReception.vue';

export default {
	name: 'ReceptionHomePage',
	components: { ReceptionHome },	
};
</script>
